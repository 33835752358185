import { template as template_e2b6d8c1d4ed48d7a5ac932633c58d89 } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SectionLink from "../section-link";
const SidebarCommonAllCategoriesSectionLink = template_e2b6d8c1d4ed48d7a5ac932633c58d89(`
  <SectionLink
    @linkName="all-categories"
    @content={{i18n "sidebar.all_categories"}}
    @route="discovery.categories"
    @prefixType="icon"
    @prefixValue="sidebar.all_categories"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllCategoriesSectionLink;
