import { template as template_1dcce4db2a4d4cc18e41a504a16eafc8 } from "@ember/template-compiler";
const FKLabel = template_1dcce4db2a4d4cc18e41a504a16eafc8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
