import { template as template_6f129b3c66654940aa6a2f92389cfa19 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6f129b3c66654940aa6a2f92389cfa19(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
