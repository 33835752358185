import { template as template_7db159b213cf439abef231bb7ba2e6f5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_7db159b213cf439abef231bb7ba2e6f5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
